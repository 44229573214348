import React from 'react';

import { addons, types } from '@storybook/addons';

import { Icons, IconButton } from '@storybook/components';

function popup(url, windowname, w, h, x, y) {
  window.open(
    url,
    windowname,
    'resizable=no,toolbar=no,scrollbars=no,menubar=no,status=no,directories=n o,width=' +
      w +
      ',height=' +
      h +
      ',left=' +
      x +
      ',top=' +
      y +
      ''
  );
  console.log('Opening: ' + windowname);
}

addons.register('my/popup', (api) => {
  addons.add('popup-addon/popup', {
    title: 'Open canvas in new popup window',
    //👇 Sets the type of UI element in Storybook
    type: types.TOOL,
    //👇 Shows the Toolbar UI element if either the Canvas or Docs tab is active
    match: ({ viewMode }) => !!(viewMode && viewMode.match(/^(story|docs)$/)),
    render: ({ active }) => (
      <IconButton
        active={active}
        title="Open canvas in new popup window"
        onClick={() => {
          const { storyId } = api.getUrlState();
          const { title } = api.getData(storyId);
          const url = `/iframe.html?id=${storyId}`;
          popup(url, title, 300, 128, 0, 0);
        }}
      >
        <Icons icon="reply" />
      </IconButton>
    ),
  });
});
